<template>
<div id="port-op-overview">
    <Overview 
        :moduleName="this.formatModuleName(this.$route.name, this.$i18n, false)"
        :moduleNamePlural="this.formatModuleName(this.$route.name, this.$i18n)"
        :sort="'id'"
        :sortOrder="-1"
        :itemService=itemService
        :itemFormRef=itemFormRef
        :modalSize="'modal-xl'"
        :modalWidth="'1400px'"
        :modalHeight="'90%'"
        :showAddButton="false"
        :showAction="false"
        :filterOptions="filterOptions"
        ref="portOverviewRef"
        @refreshList="expandAll"
    >
        <template #extrasearchoptions>
            <div class="row">
                <div class="col-auto" style="width: 30%">
                    <FormItem v-if="!isSurveyor" type="livesearch" v-model="filters.port" :service="portservice" :label="$t('sales.your_port')" @onchange="filterOnType" />
                    <FormItem v-else type="livesearch" v-model="filters.port" :options="surveyorPorts" :label="$t('sales.your_port')" @onchange="filterOnType" />
                </div>
                <div class="col-auto" style="width: 15%">
                    <FormItem type="buttongroup" v-model="filters.direction" :options="directionOptions" @onchange="filterOnType" sizeItem="12" />
                </div>
                <div class="col-auto" style="width: 40%">
                    <FormItem type="livesearch" v-model="filters.port2" :service="portservice" @onchange="filterOnType" sizeLabel="6" sizeItem="6"
                             :label="$t('sales.' + (filters.direction == 'import' ? 'loading' : 'discharge') + '_ports') + ' (' + $t('form.optional') + ')'" />
                </div>
                <div class="col-auto" style="width: 15%">
                    <FormItem type="checkbox" id="expander-checkbox" v-model="openAll" :label="$t('overview.expanded_view')" @onchange="onOpenAllChange" :disabled="isOpeningAll" />
                </div>
            </div>
        </template>
        <template #columns>
            <Column field="job_unit.make.name" sortField="job_unit.unit_make.name" :header="$t('navigation.makes')" style="width: 140px"></Column>
            <Column field="job_unit.model.name" sortField="job_unit.unit_model.name" :header="$t('navigation.models')" style="width: 140px"></Column>
            <Column field="job_unit.chassis_number" :header="$t('sales.vin')"></Column>
            <Column field="job_booking_voyage.voyage.name" :header="$t('navigation.voyages')"></Column>
            <Column field="job_unit.job.customer.relation.name" :header="$t('navigation.customers')"></Column>
            <Column field="job_booking_voyage.port_of_loading.name" sortField="job_unit.job.port~port_of_loading.name" :header="$t('sales.loading_ports')" style="width: 120px"></Column>
            <Column field="job_booking_voyage.port_of_discharge.name" sortField="job_unit.job.port~port_of_discharge.name" :header="$t('sales.discharge_ports')" style="width: 130px"></Column>
            <Column field="job_unit.job_booking.number" :header="$t('navigation.bookings')" style="width: 120px"></Column>
            <Column field="delivered_date" :header="$t('operations.date_delivered')" class="center-td" style="width: 80px">
                <template #body="slotProps">
                    <i :class="'fa ' + (slotProps.data.delivered_date && new Date(slotProps.data.delivered_date).getTime() <= new Date().getTime() ? 'fa-check text-success' : 'fa-xmark text-danger')"></i>
                </template>
            </Column>
            <Column :header="$t('operations.date_measured')" class="center-td" style="width: 80px">
                <template #body="slotProps">
                    <i :class="'fa ' + ((slotProps.data.measured_measurement && slotProps.data.measured_measurement.length && slotProps.data.measured_measurement.height && 
                                         slotProps.data.measured_measurement.width && slotProps.data.measured_measurement.weight) ? 'fa-check text-success' : 'fa-xmark text-danger')"></i>
                </template>
            </Column>
            <Column field="loaded_date" :header="$t('operations.date_loaded')" class="center-td" style="width: 75px">
                <template #body="slotProps">
                    <i :class="'fa ' + (slotProps.data.job_unit.loaded_date && new Date(slotProps.data.job_unit.loaded_date).getTime() <= new Date().getTime() ? 'fa-check text-success' : 'fa-xmark text-danger')"></i>
                </template>
            </Column>
            <Column :expander="true" :sortable="false" @click="setExpander" style='width: 30px; position: relative; z-index: 100' />
        </template>

        <template #expandedrows="slotProps">
            <div class="ps-4">
                <PortOperationsForm ref="ItemForm" :itemData="slotProps.data" :colours="colours" :hscodes="hscodes" :openAll="openAll" />
            </div>
        </template>

        <template #form>
        </template>
    </Overview>
</div>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from '@/components/column';
import PortOperationsForm from '@/components/portoperations/PortOperationsForm.vue';
import portService from '@/services/PortService';
import jobUnitService from '@/services/JobUnitService';
import colourService from "@/services/ColourService";
import hscodeService from "@/services/HscodeService";
import voyageService from "@/services/VoyageService";
import store from '@/store/user';

export default {
    components: {
        Overview,
        Column,
        PortOperationsForm
    },
    data() {
        return {
            item: {},
            itemService: jobUnitService,
            portservice: portService,
            itemFormRef: null,
            openAll: false,
            isOpeningAll: false,
            onlyOnce: true,
            isSurveyor: false,
            surveyorPorts: [],
            colours: [],
            hscodes: [],
            filters: {port: null, direction: 'export', port2: null},
            directionOptions: [{id: 'import', name: this.$t('masterdata.import')}, {id: 'export', name: this.$t('masterdata.export')}],
            
            filterOptions: [
                {
                    field: 'jobBookingVoyage.voyage_id',
                    service: voyageService,
                    name: this.$t('navigation.voyages'),
                    nameField: 'name'
                },
                {
                    field: 'is_priority',
                    radio: [{id: 1, name: this.$t('form.yes')}, {id: 0, name: this.$t('form.no')}],
                    name: this.$t('operations.status_priority')
                },
                {
                    field: 'is_cleared',
                    radio: [{id: 1, name: this.$t('form.yes')}, {id: 0, name: this.$t('form.no')}],
                    name: this.$t('operations.status_cleared')
                },
                {
                    field: 'is_on_hold',
                    radio: [{id: 1, name: this.$t('form.yes')}, {id: 0, name: this.$t('form.no')}],
                    name: this.$t('operations.status_on_hold')
                },
                {
                    field: 'is_surveyed',
                    radio: [{id: 1, name: this.$t('form.yes')}, {id: 0, name: this.$t('form.no')}],
                    name: this.$t('operations.status_surveyed')
                },
                {
                    field: 'jobUnit.delivered_date',
                    radio: [{id: 'NOT NULL', name: this.$t('form.yes')}, {id: 'NULL', name: this.$t('form.no')}],
                    name: this.$t('operations.date_delivered')
                },
                {
                    field: 'jobUnit.condition_id',
                    radio: [{id: 1, name: this.$t('form.new')}, {id: 2, name: this.$t('form.used')}],
                    name: this.$t('form.condition')
                },
            ],
        }
    },
    methods: {
        filterOnType(){
            this.$refs.portOverviewRef.setExtraParams(this.filters.port ? [this.filters.port, this.filters.direction, this.filters.port2] : false);
        },
        setExpander(e){
            console.log(e);
        },
        onOpenAllChange(e){
            this.isOpeningAll = true;
            sessionStorage.setItem('expandedview', e.target.checked ? 1 : 0);
            this.expandAll();
        },
        setSessionDataForExpandedView(){
            const expandedView = sessionStorage.getItem('expandedview');
            if(expandedView === null || expandedView === undefined){
                this.openAll = 1;
                sessionStorage.setItem('expandedview', 1);
            }
            else{
                this.openAll = Number(expandedView);
            }
        },
        expandAll(){
            window.setTimeout(() => {
                if(this.openAll){
                    this.$refs.portOverviewRef.expandAll();
                } else {
                    this.$refs.portOverviewRef.collapseAll();
                }
                this.setSessionDataForExpandedView();
                this.isOpeningAll = false;
            }, 200);
        },
        loadData(){
            colourService.index().then(response => {
                this.colours = response.data //todo
            }).catch(error => {
                console.log('error', error)
            })
            hscodeService.index().then(response => {
                this.hscodes = response.data //todo
            }).catch(error => {
                console.log('error', error)
            })
        }
    },
    mounted() {
        this.itemFormRef = this.$refs.ItemForm;
        this.setSessionDataForExpandedView();
        this.loadData();
        this.isSurveyor = store.getters.isSurveyor;
        this.surveyorPorts = store.getters.getSurveyorPorts;
    }
}
</script>

<style>
#port-op-overview .image-drop, #port-op-overview .uploaded-image{
    width: 120px;
    height: 80px;
}
#port-op-overview .image-drop{
    border: 3px dashed #888;
    color: #888;
    display: flex;
    text-align: center;
    align-items: center
}
#port-op-overview .image-drop:hover{
    border-color: #0d6efd;
    color: #0d6efd;
    cursor: pointer
}
#port-op-overview .uploaded-image{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: 0.5rem;
    border: 1px solid black;
    text-align: end
}
#port-op-overview .uploaded-image > i{
    background-color: rgba(250, 250, 250, 0.5);
    width: 20px;
    padding: 4px;
}
#port-op-overview .uploaded-image > i:hover{
    color: red;
    cursor: pointer
}
#details .fa-xmark, #details .fa-check{
    position: relative;
    font-size: 20px;
    top: 2px;
    width: 1em
}
</style>